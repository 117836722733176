import React, { useState } from "react";
import { portfolioItems } from "../constants/PortfolioData";
import Button from "./Button";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

interface PortfolioCompProps {
  showAll?: boolean;
}

const PortfolioComp: React.FC<PortfolioCompProps> = ({ showAll = false }) => {
  const navigate = useNavigate();
  const [imagesLoaded, setImagesLoaded] = useState<{ [key: string]: boolean }>(
    {}
  );

  // Optimize animation settings
  const fadeInUp = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: {
      duration: 0.4, // Reduced from 0.6
      ease: "easeOut", // Added easing function
    },
  };

  // Preload images
  const preloadImage = (src: string) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        setImagesLoaded((prev) => ({ ...prev, [src]: true }));
        resolve(src);
      };
      img.onerror = reject;
    });
  };

  // Preload all images when component mounts
  React.useEffect(() => {
    const imagesToLoad = portfolioItems
      .slice(0, showAll ? portfolioItems.length : 2)
      .map((item) => item.image);

    Promise.all(imagesToLoad.map(preloadImage)).catch((error) =>
      console.error("Error preloading images:", error)
    );
  }, [showAll]);

  const TechBadge: React.FC<any> = ({ name, Icon }) => (
    <div className="flex items-center bg-white text-black font-body rounded-full px-4 py-2 m-2 text-sm">
      <Icon className="mr-2 text-xl" />
      {name}
    </div>
  );

  return (
    <div className="text-white pb-12 px-4 md:px-8 max-w-7xl mx-auto">
      {/* Title Section - Optimized animations */}
      <div className="text-center mb-20">
        <motion.div {...fadeInUp} className="flex justify-center mb-5">
          <Button title="Our Portfolio" />
        </motion.div>

        <motion.h1
          {...fadeInUp}
          transition={{ ...fadeInUp.transition, delay: 0.1 }}
          className="text-4xl md:text-6xl font-bold pb-8 px-5 text-gradient leading-tight"
        >
          Explore Our Portfolio
          <span className="block text-2xl md:text-3xl mt-4 text-faded font-light">
            Excellence in Every Project
          </span>
        </motion.h1>

        <motion.div
          {...fadeInUp}
          transition={{ ...fadeInUp.transition, delay: 0.2 }}
          className="flex justify-center items-center w-full"
        >
          <div className="w-full md:w-2/3 lg:w-1/2">
            <p className="text-sm font-body text-faded">
              Dive into our diverse portfolio showcasing the innovative
              solutions we've delivered across various industries. Each project
              reflects our commitment to quality, creativity, and client
              satisfaction.
            </p>
          </div>
        </motion.div>
      </div>

      {/* Portfolio Grid - Optimized animations and image loading */}
      <div className="flex flex-col items-center w-full">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 md:gap-16 w-full">
          {portfolioItems
            .slice(0, showAll ? portfolioItems.length : 2)
            .map((item, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{
                  duration: 0.4,
                  ease: "easeOut",
                  delay: index * 0.1,
                }}
                className={`group relative overflow-hidden rounded-3xl bg-gradient-to-b from-gray-800/40 to-gray-900/40 backdrop-blur-sm
                  p-6 hover:shadow-2xl hover:shadow-primary/20 transition-all duration-300
                  ${index % 2 === 1 ? "md:mt-24" : "md:mb-24"}`}
              >
                <div className="relative overflow-hidden rounded-2xl aspect-video">
                  <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent z-10" />
                  {/* Optimized image loading with blur placeholder */}
                  <div className="relative w-full h-full">
                    {!imagesLoaded[item.image] && (
                      <div className="absolute inset-0 bg-gray-800 animate-pulse" />
                    )}
                    <img
                      src={item.image}
                      alt={item.title}
                      className={`w-full h-full object-cover transform transition-all duration-500
                        ${
                          imagesLoaded[item.image] ? "opacity-100" : "opacity-0"
                        }
                        ${imagesLoaded[item.image] && "group-hover:scale-110"}`}
                      loading="eager"
                      decoding="async"
                    />
                  </div>
                </div>

                <div className="relative z-20 -mt-10 p-6">
                  <div
                    className="bg-gradient-to-b from-gray-900/90 to-gray-900/90 backdrop-blur-sm rounded-xl p-6 transform 
                    group-hover:-translate-y-2 transition-transform duration-300"
                  >
                    <h3 className="text-2xl font-bold mb-4 text-gradient">
                      {item.title}
                    </h3>
                    <p className="text-faded text-sm md:text-base leading-relaxed">
                      {item.description}
                    </p>
                    <div className="flex flex-col md:flex-row flex-wrap items-center justify-between">
                      {item.onClick1 && (
                        <div className="mt-6">
                          <Button
                            title={item.button1Title}
                            onPress={item.onClick1}
                          />
                        </div>
                      )}
                      {item.onClick2 && (
                        <div className="mt-6">
                          <Button
                            title={item.button2Title}
                            onPress={item.onClick2}
                          />
                        </div>
                      )}
                    </div>
                    <div className="flex flex-wrap mt-5 justify-center">
                      {item?.tags?.map((tech: any) => (
                        <TechBadge
                          key={tech.id}
                          name={tech.label}
                          Icon={tech.icon}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </motion.div>
            ))}
        </div>

        {/* CTA Section */}
        {!showAll && (
          <motion.div
            {...fadeInUp}
            transition={{ ...fadeInUp.transition, delay: 0.3 }}
            className="mt-20"
          >
            <Button
              title="Explore All Projects"
              onPress={() => navigate("/portfolio")}
            />
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default PortfolioComp;
