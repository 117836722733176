import React from "react";

interface ButtonProps {
  title?: string;
  backgroundColor?: string;
  textColor?: string;
  borderColor?: string;
  onPress?: () => void;
  style?: string;
  isLoading?: boolean;
  type?: "button" | "submit" | "reset";
}

const Button: React.FC<ButtonProps> = ({
  title,
  backgroundColor = "bg-secondary",
  textColor = "text-white",
  borderColor = "border-transparent",
  onPress,
  isLoading = false,
  style,
}) => {
  const hoverClass =
    onPress && !isLoading
      ? "hover:opacity-60 cursor-pointer"
      : "cursor-default";
  return (
    <button
      className={`${
        style || ""
      } ${backgroundColor} ${textColor} ${borderColor} font-bold py-3 px-6 rounded-2xl ${hoverClass} flex justify-center items-center space-x-2 transition duration-300 ease-in-out border text-gradient`}
      onClick={isLoading ? undefined : onPress}
      style={{
        boxShadow: "0 0 30px rgba(3, 126, 253, 0.8)",
      }}
      disabled={isLoading}
    >
      {isLoading ? (
        <div className="loader border-2 border-t-2 border-t-transparent border-secondary w-5 h-5 rounded-full animate-spin"></div>
      ) : (
        <>
          <span>{title}</span>
          {onPress && <span className={textColor}>→</span>}
        </>
      )}
    </button>
  );
};

export default Button;
