import React from "react";
import { motion } from "framer-motion"; // Import framer-motion
import Button from "../components/Button";
import Service from "../components/Service";
import Process from "../assets/images/developmentProcess.svg";
import { serviceData } from "../constants/ServiceData";
import TechStack from "../components/TechStack";
import { Helmet } from "react-helmet-async";

const Services = () => {
  return (
    <motion.div
      className="w-full h-full text-center items-center pt-5"
      initial="hidden"
      animate="visible"
    >
      {/* Meta Tags */}
      <Helmet>
        <title>Innspirovate Tech</title>
        <meta
          name="description"
          content="At Innspirovate Tech, we harness the power of inspiration to fuel creativity. We turn that inspiration into groundbreaking innovations and continuously iterate to refine and enhance your vision, transforming it into a dynamic reality."
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Innspirovate Tech" />
        <meta
          property="og:description"
          content="Discover how Innspirovate Tech can transform your business with technology-driven solutions."
        />
        <meta
          property="og:image"
          content="https://innspirovatetech.com/static/media/logoWithName.7c6154bc510d1edd4ea89904d362b6c8.svg"
        />
        <meta property="og:url" content="https://innspirovatetech.com/" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Innspirovate Tech" />
        <meta
          name="twitter:description"
          content="Transform your business with Innspirovate Tech’s innovative solutions."
        />
        <meta
          name="twitter:image"
          content="https://innspirovatetech.com/static/media/logoWithName.7c6154bc510d1edd4ea89904d362b6c8.svg"
        />
        <link
          rel="canonical"
          href="https://innspirovatetech.com/static/media/logoWithName.7c6154bc510d1edd4ea89904d362b6c8.svg"
        />
        <meta
          name="keywords"
          content="Innspirovate Tech, innspirovatetech, tech solutions, innovative technology, software development, business growth solutions, mobile app development, website development, react-native, android, ios, react, react js, react.js, AI, Artificial Intelligence, inspire, innovate, iterate, innovation, tech, tech company, technology, cross platform development, responsiveness, responsive websites, modern websites, mobile, mobile apps, play store, app store"
        />
      </Helmet>
      {/* Header Section */}
      <motion.div
        variants={{
          hidden: { opacity: 0, y: 20 },
          visible: { opacity: 1, y: 0 },
        }}
        transition={{ duration: 0.5, delay: 0.1 }}
        className="flex justify-center py-5"
      >
        <Button title="Services We Offer" />
      </motion.div>

      <motion.h1
        variants={{
          hidden: { opacity: 0, y: 20 },
          visible: { opacity: 1, y: 0 },
        }}
        transition={{ duration: 0.5, delay: 0.2 }}
        className="text-4xl md:text-6xl font-bold pb-8 px-5 text-gradient leading-tight"
      >
        Tailored Software Innovations
        <span className="block text-2xl md:text-3xl mt-4 text-faded font-light">
          Aligned with Your Business Goals
        </span>
      </motion.h1>

      <motion.div
        variants={{
          hidden: { opacity: 0, y: 20 },
          visible: { opacity: 1, y: 0 },
        }}
        transition={{ duration: 0.5, delay: 0.3 }}
        className="flex justify-center items-center w-full p-5 md:px-0"
      >
        <div className="w-full md:w-3/4 lg:w-1/2">
          <p className="text-sm font-body text-faded">
            From tailored software development to innovative app creation and
            user-centric UX/UI design, our solutions are crafted to align
            perfectly with your business goals. Partner with us to transform
            your ideas into reality and keep your business ahead in today's
            fast-evolving digital landscape.
          </p>
        </div>
      </motion.div>

      <motion.div
        variants={{
          hidden: { opacity: 0, y: 20 },
          visible: { opacity: 1, y: 0 },
        }}
        transition={{ duration: 0.5, delay: 0.4 }}
        className="flex justify-center gap-5 pt-10"
      >
        <Button
          title="Book Free Consultation"
          onPress={() => window.open("mailto:info@innspirovatetech.com")}
        />
      </motion.div>

      <motion.div
        variants={{
          hidden: { opacity: 0, y: 20 },
          visible: { opacity: 1, y: 0 },
        }}
        transition={{ duration: 0.5, delay: 0.5 }}
      >
        <Service
          title="Services"
          heading1="Explore Our Services"
          heading2="Mastery in Every Service"
          description="Dive into our diverse portfolio showcasing the innovative solutions we've delivered across various industries. Each project reflects our commitment to quality, creativity, and client satisfaction."
        />
      </motion.div>

      {/* Services Grid */}
      <motion.div
        variants={{
          hidden: { opacity: 0, y: 20 },
          visible: { opacity: 1, y: 0 },
        }}
        transition={{ duration: 0.5, delay: 0.6 }}
        className="flex justify-center py-10"
      >
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 rounded-3xl p-5 mx-5 bg-gradient-to-b from-[#037EFD75] to-[#00000000]">
          {serviceData.map((card, index) => {
            const IconComponent = card.iconName;

            return (
              <motion.div
                key={index}
                variants={{
                  hidden: { opacity: 0, y: 30 },
                  visible: { opacity: 1, y: 0 },
                }}
                transition={{ duration: 0.5, delay: 0.7 + index * 0.1 }}
                className="p-4"
              >
                <div
                  className="relative pt-5 pb-10 px-4 rounded-3xl flex flex-col items-center justify-between transition duration-300 transform hover:scale-105"
                  style={{ boxShadow: "0 10px 15px rgba(3, 126, 253, 0.25)" }}
                >
                  <div className="absolute inset-0 rounded-3xl p-1 bg-gradient-to-b from-[#FFFFFF] to-[#037EFD]">
                    <div className="bg-custom-gradient rounded-3xl h-full w-full"></div>
                  </div>
                  <div className="relative z-10 flex flex-col h-full justify-between">
                    <div>
                      <div className="flex items-center justify-between py-4 rounded-xl w-full max-w-xs">
                        <h2 className="text-xl font-semibold text-white text-left leading-tight">
                          {card.title}
                        </h2>
                        <div className="relative p-[2px] rounded-2xl bg-gradient-to-b from-[#037EFD] to-[#FFFFFF]">
                          <div className="bg-black rounded-2xl p-2 py-2">
                            <IconComponent className="h-8 w-10 text-white" />
                          </div>
                        </div>
                      </div>
                      <p className="text-white text-sm text-left font-thin">
                        {card.description}
                      </p>
                    </div>
                  </div>
                </div>
              </motion.div>
            );
          })}
        </div>
      </motion.div>

      {/* Process Section */}
      <motion.div
        variants={{
          hidden: { opacity: 0, y: 20 },
          visible: { opacity: 1, y: 0 },
        }}
        transition={{ duration: 0.5, delay: 0.8 }}
        className="flex justify-center"
      >
        <Button title="Process" />
      </motion.div>

      <motion.h1
        variants={{
          hidden: { opacity: 0, y: 20 },
          visible: { opacity: 1, y: 0 },
        }}
        transition={{ duration: 0.5, delay: 0.9 }}
        className="text-4xl md:text-6xl font-bold pb-8 px-5 mt-5 text-gradient leading-tight"
      >
        Process We Follow
      </motion.h1>

      <motion.div
        variants={{
          hidden: { opacity: 0, y: 20 },
          visible: { opacity: 1, y: 0 },
        }}
        transition={{ duration: 0.5, delay: 1 }}
        className="flex justify-center items-center w-full p-5 md:px-0"
      >
        <div className="w-full md:w-3/4 lg:w-1/2">
          <p className="text-sm font-body text-faded">
            At Innspirovate Tech, we follow a proven process to ensure every
            project we undertake is a success. From the initial concept to the
            final launch, our team is dedicated to delivering excellence at
            every stage.
          </p>
        </div>
      </motion.div>

      <motion.div
        variants={{
          hidden: { opacity: 0, y: 20 },
          visible: { opacity: 1, y: 0 },
        }}
        transition={{ duration: 0.5, delay: 1.1 }}
        className="flex justify-center items-center px-5 md:px-40"
      >
        <img src={Process} alt="Development Process" />
      </motion.div>

      {/* Tech Stack Section */}
      <motion.div
        variants={{
          hidden: { opacity: 0, y: 20 },
          visible: { opacity: 1, y: 0 },
        }}
        transition={{ duration: 0.5, delay: 1.2 }}
        className="flex justify-center mt-0 md:-mt-20"
      >
        <Button title="Tech Stack" />
      </motion.div>

      <motion.h1
        variants={{
          hidden: { opacity: 0, y: 20 },
          visible: { opacity: 1, y: 0 },
        }}
        transition={{ duration: 0.5, delay: 1.3 }}
        className="text-4xl md:text-6xl font-bold pb-8 px-5 mt-5 text-gradient leading-tight"
      >
        Technologies We Use
      </motion.h1>

      <motion.div
        variants={{
          hidden: { opacity: 0, y: 20 },
          visible: { opacity: 1, y: 0 },
        }}
        transition={{ duration: 0.5, delay: 1.4 }}
        className="flex justify-center items-center w-full p-5 md:px-0"
      >
        <div className="w-full md:w-3/4 lg:w-1/2">
          <p className="text-sm font-body text-faded">
            At Innspirovate Tech, we utilize the latest technologies to deliver
            top-notch software solutions. Our tech stack is continuously
            evolving to keep pace with the dynamic digital landscape.
          </p>
        </div>
      </motion.div>

      <TechStack />
    </motion.div>
  );
};

export default Services;
