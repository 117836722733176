interface TeamDataType {
  name: string;
  position: string;
  image?: string;
  linkedIn?: string;
  github?: string;
  instagram?: string;
}

export const teamData: TeamDataType[] = [
  {
    name: "Abdullah Faheem",
    position: "Co-Founder & Full Stack Developer",
    image:
      "https://github.com/InnspirovateTech/Mockups/blob/main/Team/AbdullahFaheem.jpeg?raw=true",
    linkedIn: "https://www.linkedin.com/in/abdullahfaheem02",
    github: "https://github.com/AbdullahFaheem5588",
    instagram: "https://www.instagram.com/abdullah.faheem02/",
  },
  {
    name: "Adeel Shahid Khan",
    position: "Co-Founder & ML,Backend Developer",
    image:
      "https://github.com/InnspirovateTech/Mockups/blob/main/Team/AdeelShahidKhan.jpeg?raw=true",
    linkedIn: "https://www.linkedin.com/in/adeel-shahid-khan-693b72288/",
    github: "https://github.com/adeel-shahid",
    instagram: "https://www.instagram.com/_sardar.adeel/",
  },
  {
    name: "Hamid Abdullah",
    position: "Co-Founder & Mobile Developer",
    image:
      "https://github.com/InnspirovateTech/Mockups/blob/main/Team/HamidAbdullah.png?raw=true",
    linkedIn: "https://www.linkedin.com/in/hamid-abdullah-a60407202/",
    github: "https://github.com/HamidAbdullah",
    instagram: "https://www.instagram.com/hami_siddiqui8/",
  },
  {
    name: "Ehtisham Ul Haq",
    position: "Co-Founder & React Developer",
    image:
      "https://github.com/InnspirovateTech/Mockups/blob/main/Team/EhtishamUlHaq.png?raw=true",
    linkedIn: "https://www.linkedin.com/in/ehtisham-ul-haq2k2/",
    github: "https://github.com/Ehtishamulhaq2k2",
    instagram: "https://www.instagram.com/not.shaami/",
  },
  {
    name: "Hamid Basar Wahab",
    position: "Android Intern",
    image:
      "https://github.com/InnspirovateTech/Mockups/blob/main/Team/HamidBasarWahab.jpeg?raw=true",
    linkedIn: "https://www.linkedin.com/in/hamid-basar-wahab/",
    github: "https://github.com/Haamiii",
    instagram: "https://www.instagram.com/hamidkhanyousafzai.1/",
  },
  {
    name: "Ruhab Qureshi",
    position: "Flutter Intern",
    image:
      "https://raw.githubusercontent.com/InnspirovateTech/Mockups/refs/heads/main/Team/RuhabQureshi.jpg",
    linkedIn: "https://www.linkedin.com/in/muhammad-ruhab-qureshi-689044213/",
    github: "https://github.com/ruhabi57",
    instagram: "https://www.instagram.com/ruhabi57/",
  },
];
