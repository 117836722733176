import React, { useEffect, useState } from "react";

const TypingAnimation = () => {
  const texts = ["Inspire.", "Innovate.", "Iterate."];
  const [displayedText, setDisplayedText] = useState("");
  const [index, setIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);

  useEffect(() => {
    const text = texts[index];
    if (charIndex < text.length) {
      const timer = setTimeout(() => {
        setDisplayedText((prev) => prev + text[charIndex]);
        setCharIndex(charIndex + 1);
      }, 100); // Adjust typing speed here
      return () => clearTimeout(timer);
    } else {
      const timer = setTimeout(() => {
        setDisplayedText("");
        setCharIndex(0);
        setIndex((index + 1) % texts.length);
      }, 1500); // Adjust delay before switching text
      return () => clearTimeout(timer);
    }
  }, [charIndex, index]);

  return (
    <h1 className="text-4xl md:text-6xl font-bold pb-8 px-5 text-gradient leading-tight">
      {displayedText}
      <span className="animate-pulse">|</span>
    </h1>
  );
};

export default TypingAnimation;
