import React from 'react';
import { FaGithub, FaPython } from 'react-icons/fa';
import { IoLogoFigma } from 'react-icons/io5';
import { VscVscode } from "react-icons/vsc";
import { SiXcode, SiAndroidstudio, SiPostman } from "react-icons/si";



const ToolsBar = () => {
  return (
    <div className="py-5 mb-5">
      <div className="flex flex-wrap justify-center items-center gap-6 md:gap-16 py-2 px-4 rounded-lg">
        <div className="flex items-center space-x-1">
          <SiXcode className="text-3xl" style={{ color: "#B6B6B6" }} />
          <span style={{ color: "#B6B6B6" }}>X Code</span>
        </div>
        <div className="flex items-center space-x-1">
          <SiAndroidstudio className="text-3xl" style={{ color: "#B6B6B6" }} />
          <span style={{ color: "#B6B6B6" }}>Android Studio</span>
        </div>
        <div className="flex items-center space-x-1">
          <VscVscode className="text-3xl" style={{ color: "#B6B6B6" }} />
          <span style={{ color: "#B6B6B6" }}>VS Code</span>
        </div>
        <div className="flex items-center space-x-1">
          <IoLogoFigma className="text-3xl" style={{ color: "#B6B6B6" }} />
          <span style={{ color: "#B6B6B6" }}>Figma</span>
        </div>
        <div className="flex items-center space-x-1">
          <FaGithub className="text-3xl" style={{ color: "#B6B6B6" }} />
          <span style={{ color: "#B6B6B6" }}>Github</span>
        </div>
        <div className="flex items-center space-x-1">
          <SiPostman className="text-3xl" style={{ color: "#B6B6B6" }} />
          <span style={{ color: "#B6B6B6" }}>Postman</span>
        </div>
        <div className="flex items-center space-x-1">
          <FaPython className="text-3xl" style={{ color: "#B6B6B6" }} />
          <span style={{ color: "#B6B6B6" }}>PyCharm</span>
        </div>
      </div>
    </div>
  );
};

export default ToolsBar;
