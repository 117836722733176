import { useState } from "react";
import {
  FiMail,
  FiMapPin,
  FiPhone,
  FiGithub,
  FiLinkedin,
  FiInstagram,
} from "react-icons/fi";
import Button from "../components/Button";
import { motion } from "framer-motion";
import { FaWhatsapp } from "react-icons/fa";
import { FaThreads } from "react-icons/fa6";
import { toast } from "react-toastify";
import submitData from "../utils/contactUsFormSubmission";
import { Helmet } from "react-helmet-async";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [buttonIsLoading, setButtonIsLoading] = useState(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setButtonIsLoading(true);

    await submitData(formData);
    setFormData({
      name: "",
      email: "",
      subject: "",
      message: "",
    });
    setButtonIsLoading(false);
  };

  const handleChange = (e: any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const contactInfo = [
    {
      icon: FiMail,
      title: "Email",
      content: "info@innspirovatetech.com",
      onClick: "mailto:info@innspirovatetech.com",
    },
    {
      icon: FiPhone,
      title: "Phone",
      content: "+92-3345207788",
      onClick: "tel:+923345207788",
    },
    {
      icon: FiMapPin,
      title: "Location",
      content: "Rawalpindi, Pakistan",
      onClick: "https://maps.app.goo.gl/dG3BU8NPkeiPT7un9",
    },
  ];

  const socialLinks = [
    {
      icon: FaWhatsapp,
      onClick: "https://wa.me/+923345207788",
      label: "Whatsapp",
    },
    {
      icon: FiLinkedin,
      onClick: "https://www.linkedin.com/company/innspirovate-tech",
      label: "LinkedIn",
    },
    {
      icon: FiGithub,
      onClick: "https://github.com/InnspirovateTech",
      label: "GitHub",
    },
    {
      icon: FiInstagram,
      onClick: "https://www.instagram.com/innspirovatetech/",
      label: "Instagram",
    },
    {
      icon: FaThreads,
      onClick: "https://www.threads.net/@innspirovatetech",
      label: "Threads",
    },
  ];

  return (
    <div className="text-white pt-10 pb-12 px-4 md:px-8 max-w-7xl mx-auto">
      {/* Meta Tags */}
      <Helmet>
        <title>Innspirovate Tech</title>
        <meta
          name="description"
          content="At Innspirovate Tech, we harness the power of inspiration to fuel creativity. We turn that inspiration into groundbreaking innovations and continuously iterate to refine and enhance your vision, transforming it into a dynamic reality."
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Innspirovate Tech" />
        <meta
          property="og:description"
          content="Discover how Innspirovate Tech can transform your business with technology-driven solutions."
        />
        <meta
          property="og:image"
          content="https://innspirovatetech.com/static/media/logoWithName.7c6154bc510d1edd4ea89904d362b6c8.svg"
        />
        <meta property="og:url" content="https://innspirovatetech.com/" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Innspirovate Tech" />
        <meta
          name="twitter:description"
          content="Transform your business with Innspirovate Tech’s innovative solutions."
        />
        <meta
          name="twitter:image"
          content="https://innspirovatetech.com/static/media/logoWithName.7c6154bc510d1edd4ea89904d362b6c8.svg"
        />
        <link
          rel="canonical"
          href="https://innspirovatetech.com/static/media/logoWithName.7c6154bc510d1edd4ea89904d362b6c8.svg"
        />
        <meta
          name="keywords"
          content="Innspirovate Tech, innspirovatetech, tech solutions, innovative technology, software development, business growth solutions, mobile app development, website development, react-native, android, ios, react, react js, react.js, AI, Artificial Intelligence, inspire, innovate, iterate, innovation, tech, tech company, technology, cross platform development, responsiveness, responsive websites, modern websites, mobile, mobile apps, play store, app store"
        />
      </Helmet>
      {/* Title Section */}
      <div className="text-center mb-20">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="flex justify-center mb-5"
        >
          <Button title="Get in Touch" />
        </motion.div>

        <motion.h1
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.2 }}
          className="text-4xl md:text-6xl font-bold pb-8 px-5 text-gradient leading-tight"
        >
          Let's Connect
          <span className="block text-2xl md:text-3xl mt-4 text-faded font-light">
            We'd love to hear from you
          </span>
        </motion.h1>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.4 }}
          className="flex justify-center items-center w-full"
        >
          <div className="w-full md:w-2/3 lg:w-1/2">
            <p className="text-sm font-body text-faded">
              Have a project in mind? Want to collaborate? Or just want to say
              hello? Fill out the form below and we'll get back to you as soon
              as possible.
            </p>
          </div>
        </motion.div>
      </div>

      {/* Main Content */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        {/* Contact Info Cards */}
        <div className="lg:col-span-1 space-y-6">
          {contactInfo.map((info, index) => (
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6, delay: index * 0.1 }}
              key={index}
              className="relative overflow-hidden rounded-3xl bg-gradient-to-b from-gray-800/40 to-gray-900/40 backdrop-blur-sm p-6 hover:shadow-2xl hover:shadow-primary/20 transition-all duration-300"
            >
              <div className="flex items-center space-x-4">
                <div className="p-3 rounded-full bg-primary/10">
                  <info.icon className="w-6 h-6 text-gradient" />
                </div>
                <div>
                  <h3 className="text-lg font-bold text-gradient">
                    {info.title}
                  </h3>
                  <button
                    onClick={() => window.open(info.onClick, "_blank")}
                    className="text-faded hover:text-primary transition-colors"
                  >
                    {info.content}
                  </button>
                </div>
              </div>
            </motion.div>
          ))}

          {/* Social Links */}
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: 0.4 }}
            className="relative overflow-hidden rounded-3xl bg-gradient-to-b from-gray-800/40 to-gray-900/40 backdrop-blur-sm p-6"
          >
            <h3 className="text-lg font-bold text-gradient mb-4">Follow Us</h3>
            <div className="flex space-x-4">
              {socialLinks.map((social, index) => (
                <button
                  key={index}
                  onClick={() => window.open(social.onClick, "_blank")}
                  className="p-3 rounded-full bg-primary/10 hover:bg-primary/20 transition-colors"
                  aria-label={social.label}
                >
                  <social.icon className="w-5 h-5 text-gradient" />
                </button>
              ))}
            </div>
          </motion.div>
        </div>

        {/* Contact Form */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.2 }}
          className="lg:col-span-2 relative overflow-hidden rounded-3xl bg-gradient-to-b from-gray-800/40 to-gray-900/40 backdrop-blur-sm p-8"
        >
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="space-y-2">
                <label className="text-sm font-medium text-faded">Name</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full px-4 py-3 rounded-lg bg-gray-800/50 border border-gray-700 focus:border-primary focus:ring-1 focus:ring-primary outline-none transition-colors text-white"
                  required
                />
              </div>
              <div className="space-y-2">
                <label className="text-sm font-medium text-faded">Email</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full px-4 py-3 rounded-lg bg-gray-800/50 border border-gray-700 focus:border-primary focus:ring-1 focus:ring-primary outline-none transition-colors text-white"
                  required
                />
              </div>
            </div>

            <div className="space-y-2">
              <label className="text-sm font-medium text-faded">Subject</label>
              <input
                type="text"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                className="w-full px-4 py-3 rounded-lg bg-gray-800/50 border border-gray-700 focus:border-primary focus:ring-1 focus:ring-primary outline-none transition-colors text-white"
                required
              />
            </div>
            <div className="space-y-2">
              <label className="text-sm font-medium text-faded">Message</label>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                rows={6}
                className="w-full px-4 py-3 rounded-lg bg-gray-800/50 border border-gray-700 focus:border-primary focus:ring-1 focus:ring-primary outline-none transition-colors resize-none text-white"
                required
              />
            </div>

            <div className="flex justify-center">
              <Button
                type="submit"
                title={"Send Message"}
                isLoading={buttonIsLoading}
                onPress={() => console.log("Sending Message...")}
                // disabled={status === "loading"}
              />
            </div>
          </form>
        </motion.div>
      </div>
    </div>
  );
};

export default Contact;
