import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import { title } from "process";

const NotFound: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center justify-center py-20 text-center">
      <Button title="404" />
      <h1 className="text-4xl md:text-6xl font-bold pb-8 px-5 text-gradient leading-tight mt-10">
        Oops! Page Not Found
      </h1>
      <div className="flex justify-center items-center w-full p-4 md:px-0">
        <div className="w-full md:w-3/4">
          <p className="text-sm font-body text-faded mb-10">
            Sorry, the page you are looking for doesn’t exist or has been moved.
          </p>
        </div>
      </div>
      <Button title={"Go Back To Home"} onPress={() => navigate("/")} />
    </div>
  );
};

export default NotFound;
