import React from "react";
import Button from "./Button";
import logo from "../assets/logos/logoWithName.svg";
import { FaWhatsapp, FaLinkedin, FaGithub, FaInstagram, } from "react-icons/fa";
import { FaThreads } from "react-icons/fa6";

const Footer = () => {
  return (
    <footer className="bg-gradient-to-b from-[#037EFD75] to-[#00000025] text-white p-4 mx-5 rounded-3xl">
      <div className="max-w-6xl mx-auto flex justify-between items-center">
        <div>
          <div className="text-white p-8 ">
            <div className="max-w-6xl mx-auto flex flex-col md:flex-row justify-between items-center">
              <div className="flex flex-col text-center md:text-left">
                <h1 className="text-2xl">
                  Book a Call for{" "}
                  <span className="font-bold">Free Consultation </span>
                </h1>
                <p className="font-light mt-5 md:mt-0 mb-10 md:mb-0 md:w-3/4">
                  Unlock the potential of your ideas. Schedule a free
                  consultation with our experts today and discover how we can
                  bring your vision to life.
                </p>
              </div>
              <Button
                title="Call Now"
                onPress={() => {
                  const newTab = window.open(); // Try to open a new tab
                  if (newTab) {
                    // Only proceed if newTab is not null
                    newTab.location.href = "tel:+923345207788"; // Replace with your phone number
                  } else {
                    console.error(
                      "Failed to open new tab. It may be blocked by the browser."
                    );
                  }
                }}
              />
            </div>
          </div>
          <hr className="h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-white to-transparent opacity-25 dark:via-neutral-400" />
          <div className="text-white px-8 py-4">
            <div className="max-w-6xl mx-auto flex flex-col md:flex-row justify-between items-center">
              <div className="flex flex-col text-center md:text-left">
                <img
                  src={logo}
                  alt=""
                  className="w-40 self-center md:self-start"
                />
                <p className="font-light mt-5 md:mt-0 mb-10 md:mb-0 md:w-3/4">
                  Our team of experts specializes in crafting custom software
                  that drives your business forward, ensuring seamless user
                  experiences and unparalleled functionality. Partner with us to
                  transform your digital ambitions into reality.
                </p>
              </div>
              <div className="flex flex-col justify-center text-sm font-light">
                <h3 className="font-bold w-full text-center md:text-left">
                  Address
                </h3>
                <button
                  onClick={() =>
                    window.open(
                      "https://maps.app.goo.gl/dG3BU8NPkeiPT7un9",
                      "_blank"
                    )
                  }
                  className="hover:underline"
                >
                  <p className="text-center md:text-left">
                    Office #9, 2nd Floor, United Center, Shamsabad, Main Murree
                    Road,
                  </p>
                  <p className="text-center md:text-left">
                    Rawalpindi, Pakistan
                  </p>
                </button>
                <h3 className="w-full text-center md:text-left font-bold mt-5">
                  Contact Information
                </h3>
                <a
                  href="tel:+923345207788"
                  target="_blank"
                  className="hover:underline w-full text-center md:text-left "
                >
                  <p>+92-3345207788</p>
                </a>
                <a
                  href="mailto:info@innspirovatetech.com"
                  target="_blank"
                  className="hover:underline w-full text-center md:text-left "
                >
                  <p>info@innspirovatetech.com</p>
                </a>
                <div className="flex justify-center items-center md:justify-start gap-4 mt-4">
                  <button
                    className="hover:opacity-60"
                    onClick={() =>
                      window.open("https://wa.me/+923345207788", "_blank")
                    }
                  >
                    <FaWhatsapp size={25} />
                  </button>
                  <button
                    className="hover:opacity-60"
                    onClick={() =>
                      window.open(
                        "https://www.linkedin.com/company/innspirovate-tech",
                        "_blank"
                      )
                    }
                  >
                    <FaLinkedin size={25} />
                  </button>
                  <button
                    className="hover:opacity-60"
                    onClick={() =>
                      window.open(
                        "https://github.com/InnspirovateTech",
                        "_blank"
                      )
                    }
                  >
                    <FaGithub size={25} />
                  </button>
                  <button
                    className="hover:opacity-60"
                    onClick={() =>
                      window.open(
                        "https://www.instagram.com/innspirovatetech/",
                        "_blank"
                      )
                    }
                  >
                    <FaInstagram size={25} />
                  </button>
                  <button
                    className="hover:opacity-60"
                    onClick={() =>
                      window.open(
                        "https://www.threads.net/@innspirovatetech",
                        "_blank"
                      )
                    }
                  >
                    <FaThreads size={25} />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <hr className="my-12 h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-white to-transparent opacity-25 dark:via-neutral-400" />
        </div>
      </div>
      <div className="text-center text-xs mt-4">
        © 2024 Innspirovate Tech. All rights reserved
      </div>
    </footer>
  );
};

export default Footer;
