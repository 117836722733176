import { useEffect, useState } from "react";
import Slider from "react-slick";
import { teamData } from "../constants/TeamData";
import { FaLinkedin, FaGithub, FaInstagram } from "react-icons/fa";
import placeHolder from "../assets/images/placeHolder.svg";

const TeamCarousel = () => {
  const [slidesToShow, setSlidesToShow] = useState(5);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 640) {
        setSlidesToShow(1);
      } else if (window.innerWidth < 1024) {
        setSlidesToShow(3);
      } else {
        setSlidesToShow(5);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    arrows: true,
    className: "team-slider",
  };

  return (
    <div className="relative w-full">
      <style>
        {`
          .team-slider .slick-slide {
            padding: 0 4px;
          }
          
          .team-slider .slick-prev,
          .team-slider .slick-next {
            z-index: 10;
            width: 30px;
            height: 30px;
            background: #1f2937;
            border-radius: 50%;
          }

          .team-slider .slick-prev:hover,
          .team-slider .slick-next:hover {
            background: #374151;
          }

          .team-slider .slick-prev {
            left: 10px;
          }

          .team-slider .slick-next {
            right: 10px;
          }

          .team-slider .slick-prev:before,
          .team-slider .slick-next:before {
            color: white;
            font-size: 20px;
          }

          .team-slider .slick-track {
            display: flex;
            align-items: center;
          }

          .team-slider .slick-slide.slick-center {
            transform: scale(1.05);
            transition: transform 300ms;
            z-index: 1;
          }
        `}
      </style>

      <div className="px-8">
        <Slider {...settings}>
          {teamData.map((member, index) => {

            return (
              <div key={index} className="px-1">
                <div className="relative bg-[#1E1E1E] rounded-lg overflow-hidden">
                  <img
                    src={member.image ? member.image : placeHolder}
                    alt={member.name}
                    className="w-full aspect-[1/2] object-cover"
                  />
                  <div className="absolute bottom-0 left-0 right-0 px-3 py-1 bg-black/30 backdrop-blur-md">
                    <div className="flex flex-col items-center justify-between">
                      <h3 className="text-sm font-medium text-white">
                        {member.name}
                      </h3>
                      <p className="text-xs text-gray-400">{member.position}</p>
                    </div>
                    <div className="flex justify-center text-white items-center gap-5 mt-2">
                      {member.linkedIn && (
                        <button
                          className="hover:opacity-60"
                          onClick={() => window.open(member.linkedIn, "_blank")}
                        >
                          <FaLinkedin size={20} />
                        </button>
                      )}
                      {member.github && (
                        <button
                          className="hover:opacity-60"
                          onClick={() => window.open(member.github, "_blank")}
                        >
                          <FaGithub size={20} />
                        </button>
                      )}
                      {member.instagram && (
                        <button
                          className="hover:opacity-60"
                          onClick={() =>
                            window.open(member.instagram, "_blank")
                          }
                        >
                          <FaInstagram size={20} />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default TeamCarousel;
