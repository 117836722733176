import emailjs from "emailjs-com";
import { toast } from "react-toastify";
import submitData from "./contactUsFormSubmission";

const sendEmail = async (userEmail: string) => {
  const templateParams = {
    to_email: userEmail,
  };

  try {
    const response = await emailjs.send(
      "service_hv74t7b",
      "template_xvzb177",
      templateParams,
      "uPuTHZ9k4nCvF5snp"
    );
    console.log("Email sent successfully:", response.status, response.text);
    toast.success("Email sent successfully!");
    submitData({
      name: "",
      email: userEmail,
      subject: "Proposal",
      message: "Proposal Sent",
    });
  } catch (error) {
    console.error("Failed to send email:", error);
    toast.error("An error occurred. Please try again later!");
  }
};

export default sendEmail;
