import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { serviceData } from "../constants/ServiceData";

const CardSlider = () => {
  const [slidesToShow, setSlidesToShow] = useState(3);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 640) {
        setSlidesToShow(1);
      } else if (window.innerWidth < 1024) {
        setSlidesToShow(2);
      } else {
        setSlidesToShow(3);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    arrows: true,
  };

  return (
    <div className="flex flex-col justify-center items-center rounded-2xl bg-gradient-to-b from-[#C9C9C9] to-[#00000000] p-5">
      {/* Slider Container */}
      <Slider {...settings} className="w-full max-w-6xl">
        {serviceData.map((card, index) => {
          const IconComponent = card.iconName; // Use the icon dynamically

          return (
            <div className="p-4" key={index}>
              <div
                className="relative pt-5 pb-10 px-4 rounded-3xl flex flex-col items-center justify-between transition duration-300 transform hover:scale-105"
                style={{ boxShadow: "0 10px 15px rgba(3, 126, 253, 0.25)" }}
              >
                <div className="absolute inset-0 rounded-3xl p-1 bg-gradient-to-b from-[#FFFFFF] to-[#037EFD]">
                  <div className="bg-custom-gradient rounded-3xl h-full w-full"></div>
                </div>
                <div className="relative z-10 flex flex-col h-full justify-between">
                  <div>
                    <div className="flex items-center justify-between py-4 rounded-xl w-full max-w-xs">
                      <h2 className="text-xl font-semibold text-white text-left leading-tight">
                        {card.title}
                      </h2>

                      {/* Gradient Border for Icon Container */}
                      <div className="relative p-[2px] rounded-2xl bg-gradient-to-b from-[#037EFD] to-[#FFFFFF]">
                        <div className="bg-black rounded-2xl p-2 py-2">
                          {/* Render the icon */}
                          <IconComponent className="h-8 w-10 text-white" />
                        </div>
                      </div>
                    </div>

                    <p className="text-white text-sm text-left font-thin">
                      {card.description}
                    </p>
                  </div>
                  {/* 
                    <button className="text-blue-300 mt-4 text-left">
                    Learn More
                  </button>
                    */}
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default CardSlider;
