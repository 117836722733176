import React, { useEffect, useState } from "react";
import logo from "../assets/logos/logoWithName.svg";

const SplashScreen: React.FC<{ onComplete: () => void }> = ({ onComplete }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
      // Call onComplete after fading out
      setTimeout(onComplete, 800); // Adjust duration to match your fade-out time
    }, 1000); // Duration for the splash screen display

    return () => clearTimeout(timer);
  }, [onComplete]);

  return (
    <div
      className="fixed inset-0 bg-custom-gradient flex justify-center items-center"
    >
      <div
        className={`transition-opacity duration-700 ${visible ? "opacity-100" : "opacity-0"}`}
      >
        <img src={logo} alt="Logo" className="h-20 w-auto md:h-40" />
      </div>
    </div>
  );
};

export default SplashScreen;
