import React, { useState } from "react";
import { createRoot } from "react-dom/client"; // Import createRoot
import "./index.css";
import App from "./App";
import SplashScreen from "./components/SplashScreen"; // Import the SplashScreen component
import { ToastContainer } from "react-toastify";

const Main: React.FC = () => {
  const [loading, setLoading] = useState(true);

  const handleSplashComplete = () => {
    setLoading(false);
  };

  return (
    <>
      {loading ? <SplashScreen onComplete={handleSplashComplete} /> : <App />}
    </>
  );
};

// Create the root and render the Main component
const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <Main />
    <ToastContainer
      position="bottom-right"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="dark"
    />
  </React.StrictMode>
);
