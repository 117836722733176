import React from "react";
import { IconType } from "react-icons";
import {
  FaReact,
  FaNodeJs,
  FaWordpress,
  FaHtml5,
  FaCss3Alt,
  FaJs,
  FaPhp,
  FaLaravel,
  FaDatabase,
  FaSwift,
  FaPython,
} from "react-icons/fa";
import {
  SiNextdotjs,
  SiVuedotjs,
  SiAngular,
  SiTypescript,
  SiExpress,
  SiNestjs,
  SiPostgresql,
  SiMongodb,
  SiShopify,
  SiDrupal,
  SiJoomla,
  SiMagento,
  SiWebflow,
  SiFlutter,
  SiMicrosoftsqlserver,
  SiFirebase,
  SiDotnet,
  SiTailwindcss,
  SiMui,
  SiBootstrap,
  SiApollographql,
  SiCanvas,
} from "react-icons/si";
import { TbBrandReactNative } from "react-icons/tb";
import { BiLogoFlask } from "react-icons/bi";

// Define the tech stack data structure
interface Tech {
  name: string;
  icon: IconType;
}

const techStack = {
  frontend: [
    { name: "Next.js", icon: SiNextdotjs },
    { name: "React.js", icon: FaReact },
    { name: "React Native", icon: TbBrandReactNative },
    { name: "Swift", icon: FaSwift },
    { name: "Flutter", icon: SiFlutter },
    { name: "JavaScript", icon: FaJs },
    { name: "TypeScript", icon: SiTypescript },
    { name: "Fabric.js", icon: SiCanvas },
    { name: "HTML", icon: FaHtml5 },
    { name: "Tailwind CSS", icon: SiTailwindcss },
    { name: "CSS", icon: FaCss3Alt },
    { name: "Material UI", icon: SiMui },
    { name: "Bootstrap", icon: SiBootstrap },
  ],
  backend: [
    { name: "Web API", icon: SiDotnet },
    { name: "Apollo Graph QL", icon: SiApollographql },
    { name: "Node.js", icon: FaNodeJs },
    { name: "Express.js", icon: SiExpress },
    { name: "Python", icon: FaPython },
    { name: "Flask", icon: BiLogoFlask },
  ],
  cms: [
    { name: "WordPress", icon: FaWordpress },
    { name: "Shopify", icon: SiShopify },
    { name: "Wix", icon: SiWebflow },
  ],
  database: [
    { name: "Microsoft Sql Server", icon: SiMicrosoftsqlserver },
    { name: "MySQL", icon: FaDatabase },
    { name: "Firebase", icon: SiFirebase },
    { name: "MongoDB", icon: SiMongodb },
  ],
};

// Props interface for the TechBadge component
interface TechBadgeProps {
  name: string;
  Icon: IconType;
}

// Badge component with icons
const TechBadge: React.FC<TechBadgeProps> = ({ name, Icon }) => (
  <div className="flex items-center bg-white text-black font-body rounded-full px-4 py-2 m-2 text-sm">
    <Icon className="mr-2 text-xl" />
    {name}
  </div>
);

// Props interface for the TechStackSection component
interface TechStackSectionProps {
  title: string;
  items: Tech[];
}

// Section component to display the list of tech badges
const TechStackSection: React.FC<TechStackSectionProps> = ({
  title,
  items,
}) => (
  <div className="w-full p-4">
    <h3 className="text-lg font-bold  text-gradient mb-4 text-center">
      {title}
    </h3>
    <div className="flex flex-wrap justify-center">
      {items.map((tech) => (
        <TechBadge key={tech.name} name={tech.name} Icon={tech.icon} />
      ))}
    </div>
  </div>
);

// Main component to render the tech stack
const TechStack: React.FC = () => {
  return (
    <div className="flex align-center justify-center p-8 rounded-lg">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <TechStackSection title="Front End" items={techStack.frontend} />
        <TechStackSection title="Back End" items={techStack.backend} />
        <TechStackSection title="CMS" items={techStack.cms} />
        <TechStackSection title="Database" items={techStack.database} />
      </div>
    </div>
  );
};

export default TechStack;
