import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

import TextField from "../components/TextField";
import CardSlider from "../components/CardSlider";
import TypingAnimation from "../components/TypingAnimation";
import Button from "../components/Button";
import Tools from "../components/Tools";
import Portfolio from "../components/PortfolioComp";
import Service from "../components/Service";
import TeamCarousel from "../components/TeamCarousel";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import sendEmail from "../utils/emailHandler";
import { Helmet } from "react-helmet-async";

const Home = () => {
  const [email, setEmail] = useState("");
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSendEmail = async () => {
    setButtonIsLoading(true);
    await sendEmail(email);
    setEmail("");
    setButtonIsLoading(false);
  }

  return (
    <motion.div className="w-full h-full text-center justify-center items-center pt-10">
      {/* Meta Tags */}
      <Helmet>
        <title>Innspirovate Tech</title>
        <meta
          name="description"
          content="At Innspirovate Tech, we harness the power of inspiration to fuel creativity. We turn that inspiration into groundbreaking innovations and continuously iterate to refine and enhance your vision, transforming it into a dynamic reality."
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Innspirovate Tech" />
        <meta
          property="og:description"
          content="Discover how Innspirovate Tech can transform your business with technology-driven solutions."
        />
        <meta
          property="og:image"
          content="https://innspirovatetech.com/static/media/logoWithName.7c6154bc510d1edd4ea89904d362b6c8.svg"
        />
        <meta property="og:url" content="https://innspirovatetech.com/" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Innspirovate Tech" />
        <meta
          name="twitter:description"
          content="Transform your business with Innspirovate Tech’s innovative solutions."
        />
        <meta
          name="twitter:image"
          content="https://innspirovatetech.com/static/media/logoWithName.7c6154bc510d1edd4ea89904d362b6c8.svg"
        />
        <link
          rel="canonical"
          href="https://innspirovatetech.com/static/media/logoWithName.7c6154bc510d1edd4ea89904d362b6c8.svg"
        />
        <meta
          name="keywords"
          content="Innspirovate Tech, innspirovatetech, tech solutions, innovative technology, software development, business growth solutions, mobile app development, website development, react-native, android, ios, react, react js, react.js, AI, Artificial Intelligence, inspire, innovate, iterate, innovation, tech, tech company, technology, cross platform development, responsiveness, responsive websites, modern websites, mobile, mobile apps, play store, app store"
        />
      </Helmet>
      {/* Hero Section */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        <TypingAnimation />
      </motion.div>

      {/* Description */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, delay: 0.2 }}
        className="flex justify-center items-center w-full p-5 md:px-0"
      >
        <div className="w-full md:w-3/4 lg:w-1/2">
          <p className="text-sm font-body text-faded mb-10">
            At Innspirovate Tech, we harness the power of inspiration to fuel
            creativity. We turn that inspiration into groundbreaking innovations
            and continuously iterate to refine and enhance your vision,
            transforming it into a dynamic reality.
          </p>
        </div>
      </motion.div>

      {/* Email Input Section */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, delay: 0.4 }}
        className="flex justify-center items-center w-full px-4 md:px-0"
      >
        <div className="w-full md:w-3/4 lg:w-1/2">
          <TextField
            showButton={true}
            placeHolder="Enter your email"
            buttonTitle="Get Proposal"
            buttonOnPress={handleSendEmail}
            buttonIsLoading={buttonIsLoading}
            value={email}
            onChange={setEmail}
            type="email"
          />
        </div>
      </motion.div>

      {/* Services Section */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, delay: 0.6 }}
      >
        <Service
          title="Our Services"
          heading1="Services offered by"
          heading2="Innspirovate Tech"
          description="Innovative software solutions designed to transform your business
              and drive growth. We empower your digital journey with expertise in
              custom development, seamless integration, and cutting-edge
              technology."
        />
      </motion.div>

      {/* Card Slider Section */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, delay: 0.8 }}
        className="p-8"
      >
        <CardSlider />
      </motion.div>

      {/* Services Button */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, delay: 1.0 }}
        className="flex justify-center mb-5"
      >
        <Button
          title="See All Services"
          onPress={() => navigate("/services")}
        />
      </motion.div>

      {/* Tools Section */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, delay: 1.2 }}
      >
        <Tools />
      </motion.div>

      {/* Portfolio Section */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, delay: 1.4 }}
      >
        <Portfolio />
      </motion.div>

      {/* Team Section */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, delay: 1.4 }}
      >
        <div className="text-center mb-12">
          <div className="flex justify-center mb-5">
            <Button title="Our Team" />
          </div>

          <h1 className="text-4xl md:text-6xl font-bold pb-8 px-5 text-gradient leading-tight">
            Meet Our Team
          </h1>
          <div className="flex justify-center items-center w-full p-4 md:px-0">
            <div className="w-full md:w-3/4">
              <p className="text-sm font-body text-faded mb-10">
                At Innspirovate Tech, our passionate team drives our mission to
                inspire, innovate, and iterate. Together, we bring diverse
                expertise and a shared vision to create exceptional solutions
                for our clients.
              </p>
            </div>
          </div>
          <TeamCarousel />
        </div>
      </motion.div>
    </motion.div>
  );
};

export default Home;
