import React, { useState, useEffect, useRef, MouseEvent } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/logos/logoWithName.svg";

const Navbar = () => {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [activeLink, setActiveLink] = useState("Home");
  const [sliderStyle, setSliderStyle] = useState<React.CSSProperties>({});
  const navRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const visible = prevScrollPos > currentScrollPos || currentScrollPos < 50;

      setPrevScrollPos(currentScrollPos);
      setVisible(visible);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos]);

  useEffect(() => {
    const path = location.pathname;
    const currentLink =
      navLinks.find((link) => link.path === path)?.name || "Home";
    setActiveLink(currentLink);

    // Simulate a click on the active link to position the slider
    const activeElement = navRef.current?.querySelector(
      `a[href="${path}"]`
    ) as HTMLElement;
    if (activeElement) {
      const linkRect = activeElement.getBoundingClientRect();
      const navRect = navRef.current?.getBoundingClientRect();

      if (navRect) {
        setSliderStyle({
          width: `${linkRect.width}px`,
          transform: `translateX(${linkRect.left - navRect.left - 8}px)`,
        });
      }
    }
  }, [location]);

  const navLinks = [
    { name: "Home", path: "/" },
    { name: "Services", path: "/services" },
    { name: "Portfolio", path: "/portfolio" },
    { name: "Contact Us", path: "/contact" },
  ];

  const handleLinkClick = (
    name: string,
    event: MouseEvent<HTMLAnchorElement>
  ) => {
    setActiveLink(name);
    setDrawerOpen(false);
    const linkElement = event.currentTarget;
    const linkRect = linkElement.getBoundingClientRect();
    const navRect = navRef.current?.getBoundingClientRect();

    if (navRect) {
      setSliderStyle({
        width: `${linkRect.width}px`,
        transform: `translateX(${linkRect.left - navRect.left - 8}px)`,
      });
    }
  };

  return (
    <>
      <nav
        className={`z-50 bg-background md:bg-transparent fixed w-full transition-all duration-700 ${
          visible ? "md:-top-0 top-0" : "md:-top-28 top-0"
        }`}
      >
        <div className="container mx-auto px-8 py-5 flex justify-between items-center md:justify-center">
          {/* Logo */}
          <button className="flex items-center md:absolute md:left-8">
            <img src={logo} alt="Logo" className="h-10 w-auto md:h-14" />
          </button>

          {/* Desktop Navigation */}
          <div
            className="relative hidden md:flex items-stretch space-x-1 bg-transparencyShade/20 rounded-2xl p-1 border border-white shadow-2xl"
            ref={navRef}
          >
            <div
              className="absolute bg-secondary rounded-2xl transition-all duration-500 ease-in-out"
              style={{
                height: "calc(100% - 8px)",
                top: "4px",
                ...sliderStyle,
              }}
            />
            {navLinks.map((link) => (
              <Link
                key={link.name}
                to={link.path}
                className={`relative z-10 text-white px-4 py-3 rounded-2xl text-sm font-body leading-xs tracking-wide transition-all duration-300 flex items-center justify-center ${
                  activeLink === link.name
                    ? ""
                    : "hover:bg-transparencyShade/20"
                }`}
                onClick={(e) => handleLinkClick(link.name, e)}
              >
                {link.name.toUpperCase()}
              </Link>
            ))}
          </div>

          {/* Mobile Menu Button */}
          <button
            className="md:hidden flex items-center text-white relative"
            onClick={() => setDrawerOpen(!drawerOpen)}
          >
            <div
              className={`relative w-6 h-6 flex flex-col justify-center items-center transition-transform duration-300 ${
                drawerOpen ? "rotate-45" : ""
              }`}
              style={{ transformOrigin: "center" }}
            >
              <div
                className={`w-6 h-0.5 bg-white transition-opacity duration-300 ${
                  drawerOpen ? "opacity-0" : ""
                }`}
                style={{ marginBottom: drawerOpen ? "0" : "5px" }} // Adjust margin for spacing
              ></div>
              <div
                className={`w-6 h-0.5 bg-white transition-opacity duration-300 ${
                  drawerOpen ? "opacity-0" : ""
                }`}
                style={{ marginBottom: drawerOpen ? "0" : "5px" }} // Adjust margin for spacing
              ></div>
              <div
                className={`w-6 h-0.5 bg-white transition-opacity duration-300 ${
                  drawerOpen ? "opacity-0" : ""
                }`}
              ></div>
              <div
                className={`w-6 h-0.5 bg-white absolute transition-all duration-300 ${
                  drawerOpen ? "opacity-100  -rotate-180" : "opacity-0 rotate-0"
                }`}
                style={{
                  top: "50%",
                  left: "50%",
                  transform: `translate(-50%, -50%) ${
                    drawerOpen ? "rotate(-180deg)" : "rotate(0deg)"
                  }`,
                  transformOrigin: "center",
                }}
              ></div>
              <div
                className={`w-6 h-0.5 bg-white absolute transition-all duration-300 ${
                  drawerOpen ? "opacity-100  rotate-90" : "opacity-0 rotate-0"
                }`}
                style={{
                  top: "50%",
                  left: "50%",
                  transform: `translate(-50%, -50%) ${
                    drawerOpen ? "rotate(90deg)" : "rotate(0deg)"
                  }`,
                  transformOrigin: "center",
                }}
              ></div>
            </div>
          </button>
        </div>
      </nav>

      {/* Mobile Drawer */}
      <div
        className={`md:hidden fixed top-[5rem] left-0 right-0 bg-gradient-to-br from-background via-background to-black overflow-hidden transition-all duration-[1000ms] ease-in-out z-50 border-b-2 border-white ${
          drawerOpen ? "max-h-screen" : "max-h-0"
        }`}
      >
        <div className="w-full p-14 text-center">
          <div className="flex flex-col space-y-4">
            {navLinks.map((link) => (
              <Link
                key={link.name}
                to={link.path}
                className={`text-white hover:text-textHover text-xs font-body leading-xs tracking-wide ${
                  activeLink === link.name ? "text-gradient" : ""
                }`}
                onClick={(e) => handleLinkClick(link.name, e)}
              >
                {link.name.toUpperCase()}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
