import React from "react";
import PortfolioComp from "../components/PortfolioComp";
import Button from "../components/Button";
import TeamCarousel from "../components/TeamCarousel";
import { Helmet } from "react-helmet-async";

const Portfolio = () => {
  return (
    <div>
      {/* Meta Tags */}
      <Helmet>
        <title>Innspirovate Tech</title>
        <meta
          name="description"
          content="At Innspirovate Tech, we harness the power of inspiration to fuel creativity. We turn that inspiration into groundbreaking innovations and continuously iterate to refine and enhance your vision, transforming it into a dynamic reality."
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Innspirovate Tech" />
        <meta
          property="og:description"
          content="Discover how Innspirovate Tech can transform your business with technology-driven solutions."
        />
        <meta
          property="og:image"
          content="https://innspirovatetech.com/static/media/logoWithName.7c6154bc510d1edd4ea89904d362b6c8.svg"
        />
        <meta property="og:url" content="https://innspirovatetech.com/" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Innspirovate Tech" />
        <meta
          name="twitter:description"
          content="Transform your business with Innspirovate Tech’s innovative solutions."
        />
        <meta
          name="twitter:image"
          content="https://innspirovatetech.com/static/media/logoWithName.7c6154bc510d1edd4ea89904d362b6c8.svg"
        />
        <link
          rel="canonical"
          href="https://innspirovatetech.com/static/media/logoWithName.7c6154bc510d1edd4ea89904d362b6c8.svg"
        />
        <meta
          name="keywords"
          content="Innspirovate Tech, innspirovatetech, tech solutions, innovative technology, software development, business growth solutions, mobile app development, website development, react-native, android, ios, react, react js, react.js, AI, Artificial Intelligence, inspire, innovate, iterate, innovation, tech, tech company, technology, cross platform development, responsiveness, responsive websites, modern websites, mobile, mobile apps, play store, app store"
        />
      </Helmet>
      <div className="w-full h-full text-center justify-center items-center pt-10">
        <PortfolioComp showAll={true} />
      </div>
    </div>
  );
};

export default Portfolio;
