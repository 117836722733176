import { toast } from "react-toastify";

const SCRIPT_URL =
  "https://script.google.com/macros/s/AKfycbyTk6UE9GeQjci__FmfihQUBqBY54-4Mg9HY3m1s57kf6lTLlp1SiBn-47UTrgqWF_u4g/exec";

interface formDataType {
  name: string;
  email: string;
  subject: string;
  message: string;
}

const submitData = async (formData: formDataType) => {
  try {
    // Create URL-encoded string directly from form data
    const formDataString = new URLSearchParams({
      name: formData.name,
      email: formData.email,
      subject: formData.subject,
      message: formData.message,
    }).toString();

    await fetch(SCRIPT_URL, {
      method: "POST",
      mode: "no-cors",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formDataString,
    });
    if (formData.name !== "") {
      toast.success(
        "Message sent successfully. We'll get back with you shortly!"
      );
    }
  } catch (error) {
    console.error("Form submission error:", error);
    if (formData.name !== "") {
      toast.error("An unexpected error occurred. Please try again later!");
    }
  }
};

export default submitData;
