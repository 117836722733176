import React from 'react';
import Button from './Button';

interface ServiceProps {
  title: string;
  heading1?: string;
  heading2?: string;
  description: string;
}

const Service: React.FC<ServiceProps> = ({ title, heading1, heading2, description }) => {
  return (
    <>
      <div className="flex justify-center mt-14 mb-5">
        <Button title={title} />
      </div>
      <h1 className="text-4xl md:text-6xl font-bold pb-8 px-5 text-gradient leading-tight">
        {heading1}
        {
          heading2 && 
          <span className="block text-2xl md:text-3xl mt-4 text-faded font-light">
            {heading2}
          </span>
        }
      </h1>
      <div className="flex justify-center items-center w-full p-4 md:px-0">
        <div className="w-full md:w-3/4 lg:w-1/2">
          <p className="text-sm font-body text-faded">{description}</p>
        </div>
      </div>
    </>
  );
};

export default Service;
