import { MdComputer } from "react-icons/md";
import { CiMobile1 } from "react-icons/ci";
import { MdOutlineColorLens } from "react-icons/md";
import { FaWordpress } from "react-icons/fa";
import { RiOrganizationChart } from "react-icons/ri";
import { TbRobot } from "react-icons/tb";
import { AiOutlineGlobal } from "react-icons/ai";



export const serviceData = [
  {
    title: "Website Development",
    description:
      "Build a powerful online presence with our expert website development services. We create responsive, user-friendly websites that captivate your audience and drive business growth.",
    iconName: MdComputer,
  },
  {
    title: "App Development",
    description:
      "Transform your ideas into intuitive, high-performing mobile apps. Our development team crafts seamless, engaging apps that enhance user experience and drive business success.",
    iconName: CiMobile1,
  },
  {
    title: "AI & Machine Learning",
    description:
      "Innovating your business with Artificial Intelligence, Machine Learning, and Advanced Automation, delivering innovative solutions for real-time insights and decision-making.",
    iconName: TbRobot,
  },
  {
    title: "UX/UI Designing",
    description:
      "Design experiences that resonate with your users. Our UX/UI design services focus on creating visually stunning, user-centric interfaces that elevate your brand and ensure intuitive navigation.",
    iconName: MdOutlineColorLens,
  },
  {
    title: "Wordpress Development",
    description:
      "Build a powerful online presence with our expert website development services. We create responsive, user-friendly websites that captivate your audience and drive business growth.",
    iconName: FaWordpress,
  },
  {
    title: "Digital Marketing",
    description:
      "Transform your ideas into intuitive, high-performing mobile apps. Our development team crafts seamless, engaging apps that enhance user experience and drive business success.",
    iconName: RiOrganizationChart,
  },
  {
    title: "Social Media Management",
    description:
      "Grow your brand with impactful social media strategies. We help you connect, engage, and expand your audience. Let us manage your channels, so you can focus on what you do best.",
    iconName: AiOutlineGlobal,
  },
];
