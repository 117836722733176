import React from "react";
import Button from "./Button";

interface TextFieldProps {
  placeHolder?: string;
  type?: string;
  showButton?: boolean;
  value?: string;
  buttonTitle?: string;
  buttonOnPress?: () => void;
  buttonIsLoading?: boolean;
  onChange?: (text: string) => void;
}

const TextField: React.FC<TextFieldProps> = ({
  placeHolder = "Enter your text",
  type = "text",
  showButton = false,
  value = "",
  buttonTitle,
  buttonOnPress,
  buttonIsLoading = false,
  onChange,
}) => {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (buttonOnPress) {
      buttonOnPress();
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="items-center w-full rounded-2xl"
      style={{
        boxShadow: "0 0 30px rgba(3, 126, 253, 0.6)",
      }}
    >
      <div className="flex justify-between items-center w-full bg-background border border-secondary rounded-2xl focus:border-secondary focus:outline-none">
        <input
          type={type}
          value={value}
          onChange={(e) => onChange && onChange(e.target.value)}
          placeholder={placeHolder}
          className="flex-grow min-w-0 bg-background text-white placeholder-faded px-4 py-4 rounded-2xl focus:outline-none"
          required
        />
        {showButton && (
          <div className="flex-shrink-0">
            <Button
              title={buttonTitle}
              onPress={() => console.log("Sending Proposal!")}
              style={"px-2"}
              isLoading={buttonIsLoading}
            />
          </div>
        )}
      </div>
    </form>
  );
};

export default TextField;
